var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "brandStoriesListFavorites" },
    [
      _c("LoadingOverlay", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLoader,
            expression: "showLoader",
          },
        ],
      }),
      _c(
        "masonry",
        {
          key: _vm.key,
          attrs: { cols: { default: 4, 1200: 2 }, gutter: { default: "15px" } },
        },
        _vm._l(this.brandStories, function (brandStory, index) {
          return _c("BrandStoryTile", {
            key: "brandStoryTile" + brandStory.id,
            attrs: { brandStory: brandStory, index: index },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }