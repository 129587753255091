var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cardProfile" },
    [
      _c(
        "div",
        {
          staticClass: "circleBorderProfileImg",
          class: _vm.engagementLevelClass,
        },
        [
          _c("UserImageThumbnail", {
            class: "imgProfile",
            attrs: { thumbnailAssetId: _vm.profile.thumbnailAssetId },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: this.onYourProfile,
                  expression: "this.onYourProfile",
                },
              ],
              staticClass: "editProfile",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.editUserProfile.apply(null, arguments)
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { icon: ["fas", "pencil"] },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "txt bold" }, [
        _vm._v(
          _vm._s(_vm.profile.nameFirst) + " " + _vm._s(_vm.profile.nameLast)
        ),
      ]),
      _vm.position
        ? _c("div", { staticClass: "txt" }, [_vm._v(_vm._s(_vm.position))])
        : _vm._e(),
      _vm.profile.email
        ? _c("div", { staticClass: "txt" }, [
            _c(
              "a",
              {
                staticClass: "email",
                attrs: { href: "mailto:" + _vm.profile.email },
              },
              [_vm._v(_vm._s(_vm.profile.email))]
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "txt" }, [
        _vm._v(_vm._s(_vm.profile.dealership)),
      ]),
      _c("div", { staticClass: "followerSection" }, [
        _c("div", [
          _c("div", { staticClass: "txt bold large" }, [
            _vm._v(_vm._s(_vm.profile.followsCount)),
          ]),
          _c("div", { staticClass: "txt small" }, [_vm._v("FOLLOWERS")]),
        ]),
        _c("div", { staticClass: "middle" }, [
          _c("div", { staticClass: "txt bold large" }, [
            _vm._v(_vm._s(_vm.postCount)),
          ]),
          _c("div", { staticClass: "txt small" }, [_vm._v("POSTS")]),
        ]),
        _c("div", [
          _c("div", { staticClass: "txt bold large" }, [
            _vm._v(_vm._s(_vm.profile.likes)),
          ]),
          _c("div", { staticClass: "txt small" }, [_vm._v("LIKES")]),
        ]),
      ]),
      _vm.onYourProfile ? _c("BrandStorySubmissionContainerAlt") : _vm._e(),
      !_vm.onYourProfile ? _c("CardProfileFollow") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }