var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cardProfileFollow", on: { click: _vm.toggleFollow } },
    [
      _c("div", { staticClass: "copy", class: _vm.disabledClick }, [
        _vm.copy === "Follow"
          ? _c(
              "div",
              [
                _c("span", [_vm._v(_vm._s(_vm.copy))]),
                _c("br"),
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "user-plus"] },
                }),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("span", [_vm._v(_vm._s(_vm.copy))]),
                _c("br"),
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "user-minus"] },
                }),
              ],
              1
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }